.help_main_title {
  font-weight: 900 !important;
}

.help_main_subtitle {
  font-size: 16px !important;
  font-weight: 600;
}

.help_container {
  max-width: 800px;
  margin: 0px auto;
}

.help_list {
  box-shadow: 0px 6px 9px 5px #d5d5d5;
  margin-top: 30px;
  padding: 18px 18px 0px 18px;
  border-radius: 10px;
}

.help_list > .help_title {
  margin-bottom: 15px;
  padding-left: 5px;
}

.help_list_group {
  margin: 0px;
  padding: 0px;
  padding-bottom: 10px;
  list-style: none;
}

.help_list_item {
  border-top: solid 1px lightgray;
  margin: 0px 5px;
}

.help_list_item > .alf-panel > .summary {
  padding: 0 18px 0 18px !important;
}

.help_list_item > .alf-panel > .summary > .button {
  margin-top: 0px !important;
}

.help_list_item > .alf-panel > .summary > .content {
  margin: 18px 0 !important;
}

.help_list_item > .alf-panel:hover {
  background-color: #e8e4fa !important;
}

.help_list_item > .alf-panel.expanded {
  background-color: #e8e4fa !important;
}

.help_list_item > .alf-panel.expanded > .summary {
  border-left: solid 5px #483499;
}

.help_list_item > .alf-panel.onFocus {
  box-shadow: 0px 0px 0px 0px !important;
  margin: 0px !important;
  padding: 0px !important;
}

.help_spinner {
  display: flex;
  justify-content: center;
}
